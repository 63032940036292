import { CitizenNotificationMethod } from '~shared/dtos/letters.dto'
import { TemplateUpdateType } from '~shared/dtos/templates.dto'

export const pluraliseIfNeeded = (
  collection: any[],
  singular: string,
): string => (collection.length > 1 ? `${singular}s` : singular)

export const pluraliseIfNeededForCount = (
  count: number,
  singular: string,
  customSuffix?: string,
): string => (count > 1 ? `${singular}${customSuffix ?? 's'}` : singular)

export const citizenNotificationMethodToString = (
  citizenNotificationMethod: CitizenNotificationMethod,
): string => {
  switch (citizenNotificationMethod) {
    case CitizenNotificationMethod.SMS:
      return 'SMS'
    case CitizenNotificationMethod.EMAIL:
      return 'email'
    case CitizenNotificationMethod.PHYSICAL:
      return 'SingPost'
    default:
      return ''
  }
}

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const isJsonString = (str: string): boolean => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const templateActionTypeToString = (
  actionType: TemplateUpdateType,
): string => {
  switch (actionType) {
    case TemplateUpdateType.HTML:
      return 'Edited by'
    case TemplateUpdateType.ARCHIVE:
      return 'Archived by'
    case TemplateUpdateType.RECOVER:
      return 'Restored by'
    default:
      return ''
  }
}
