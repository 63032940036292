export const ACCEPTED_TEMPLATE_FIELDS_REGEX =
  /{{(\s|&nbsp;)*([a-zA-Z0-9_]+)(\s|&nbsp;)*}}/g

// for any of the variables names edited by the officers
// we match anything inside curly braces apart from more
// curly braces
export const ACCEPTED_EDITED_TEMPLATE_FIELDS_REGEX =
  /{{(\s|&nbsp;)*([^{}])*(\s|&nbsp;)*}}/g

export const ACCEPTED_TEMPLATE_NAME_REGEX =
  /^[A-Za-z0-9\s–—@'"’\-()[\]:#_&|,.?!/+]+$/

export const ACCEPTED_SINGAPORE_PHONE_NUMBERS_REGEX =
  /^(\+65|65|65-|\+65-)?([89]\d{7})$/

export const ACCEPTED_CITIZEN_POSTAL_CODE_REGEX = /^\d{6}$/

export const ACCEPTED_SINGAPORE_PHONE_NUMBERS_REGEX_WITHOUT_65 = /^([89]\d{7})$/

// taken from https://emailregex.com/
export const ACCEPTED_CITIZEN_EMAIL_REGEX =
  //eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const ALPHANUMERIC_REGEX = /^(?=.*[a-zA-Z])(?=.*[0-9])/

export const WHITESPACES_REGEX = /\s/

export const LETTER_PUBLIC_ID_REGEX = /^(([a-z0-9]{5})-){3}([a-z0-9]{5}){1}$/
