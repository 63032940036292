export const jsonArrToCsvString = (
  jsonArr: { [key: string]: string }[],
  selectedKeys?: string[],
): string => {
  const replacerFn = (_key: string, value: string) =>
    value === null ? '' : value
  const headers = selectedKeys || Object.keys(jsonArr[0])
  const csv = jsonArr.map((row) =>
    headers.map((header) => JSON.stringify(row[header], replacerFn)).join(','),
  )
  csv.unshift(headers.join(','))
  const csvString = csv.join('\r\n')
  return csvString
}
