import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import { adminQueryKeys } from '~constants/query-keys'
import { useToast } from '~hooks/useToast'
import { api } from '~lib/api'
import {
  GetBatchDto,
  GetBatchesResponseDto,
  GetBatchPdfDto,
} from '~shared/dtos/batches.dto'
import { GetTemplateResponseDto } from '~shared/dtos/templates.dto'

export const useGetTemplates = ({
  limit,
  offset,
  isArchived = false,
}: {
  limit: number
  offset: number
  isArchived?: boolean
}) => {
  const { data, isLoading } = useQuery(
    adminQueryKeys.templates({ limit: limit, offset: offset, isArchived }),
    () =>
      api
        .url(
          `/templates?limit=${limit}&offset=${offset}${
            isArchived ? '&archived=true' : ''
          }`,
        )
        .get()
        .json<GetTemplateResponseDto>(),
  )

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return {
    templates: data?.templates ?? [],
    count: data?.count,
    isTemplatesLoading: isLoading,
  }
}

export const useBatchId = (): number => {
  const { batchId } = useParams()
  if (!batchId) throw new Error('No batchId provided')
  return Number(batchId)
}

export const useGetBatchById = (batchId: number) => {
  const { data, isLoading } = useQuery(
    adminQueryKeys.batches({ batchId: batchId }),
    () => api.url(`/batches/${batchId}`).get().json<GetBatchDto>(),
    {
      enabled: !!batchId && batchId !== -1,
    },
  )
  return {
    batchDetails: data,
    isBatchLoading: isLoading,
  }
}

export const useGetBatchByIdFetchQuery = () => {
  const toast = useToast({ isClosable: true })
  const queryClient = useQueryClient()
  const getBatchById = async ({
    batchId,
    errorMessage,
    onSuccess,
    onError,
  }: {
    batchId: number
    errorMessage?: string
    onSuccess?: (batchData: GetBatchDto) => void
    onError?: () => void
  }) => {
    try {
      const batchData = await queryClient.fetchQuery({
        queryKey: adminQueryKeys.batches({ batchId: batchId }),
        queryFn: async () =>
          await api.url(`/batches/${batchId}`).get().json<GetBatchDto>(),
      })
      onSuccess?.(batchData)
    } catch (e) {
      errorMessage &&
        toast({
          title: errorMessage,
          status: 'error',
        })
      onError?.()
    }
  }
  return { getBatchById: getBatchById }
}

export const useGetBatchPdfById = () => {
  const toast = useToast()
  const { isLoading, mutate } = useMutation(
    (batchId: number) =>
      api.url(`/batches/${batchId}/pdfs`).get().json<GetBatchPdfDto>(),
    {
      onSuccess: (data: GetBatchPdfDto) => {
        // if pdf presigned url is ready, open presigned link in new window
        if (data && data.isReady) {
          window.open(data.presignedUrl, '_blank')
        } else {
          toast({
            status: 'success',
            description: `Your PDFs are being generated, and will be sent directly to your email inbox.`,
          })
        }
      },
      onError: () => {
        toast({
          status: 'error',
          description: 'Your PDF failed to download. Please try again.',
        })
      },
    },
  )
  return {
    isBatchPdfUrlLoading: isLoading,
    getBatchPdfUrl: mutate,
  }
}

export const useGetBatches = (limit: number, offset: number) => {
  const { data, isLoading } = useQuery(
    adminQueryKeys.batches({ limit: limit, offset: offset }),
    () =>
      api
        .url(`/batches?limit=${limit}&offset=${offset}`)
        .get()
        .json<GetBatchesResponseDto>(),
  )
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return {
    batches: data?.batches,
    count: data?.count,
    isBatchesLoading: isLoading,
  }
}
