import {
  Button,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { useToast } from '@opengovsg/design-system-react'
import { useState } from 'react'
import { BiHelpCircle, BiX } from 'react-icons/bi'
import { BsPencilFill } from 'react-icons/bs'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

import { routes } from '~constants/routes'
import CreateTemplateModal, {
  SOURCE_TYPE,
} from '~features/editor/components/editor/CreateTemplateModal'
import { EditorIntroModal } from '~features/editor/components/editor/EditorIntroModal'
import ExitEditorModal from '~features/editor/components/editor/ExitEditorModal'
import { TemplateEditor } from '~features/editor/components/TemplateEditor'
import { ACCEPTED_TEMPLATE_NAME_REGEX } from '~shared/constants/regex'
import { GrowthBookFeatures } from '~shared/types/feature-flag'

import { EditorLetterPreview } from './components/EditorLetterPreview'
import {
  useCreatePdfPreview,
  useCreateTemplateMutation,
} from './hooks/create.hooks'

export const CreateTemplatePage = (): JSX.Element => {
  const toast = useToast()

  const navigate = useNavigate()
  const location = useLocation()

  const template =
    //eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    (location?.state?.template as { html: string; name: string }) || ''

  const [templateName, setTemplateName] = useState(template.name || '')
  const [templateContent, setTemplateContent] = useState(template.html || '')

  const {
    isOpen: isCreateModalOpen,
    onOpen: onCreateModalOpen,
    onClose: onCreateModalClose,
  } = useDisclosure()

  const {
    isOpen: isExitModalOpen,
    onOpen: onExitModalOpen,
    onClose: onExitModalClose,
  } = useDisclosure()

  const {
    isOpen: isEditorIntroOpen,
    onOpen: onEditorIntroOpen,
    onClose: onEditorIntroClose,
  } = useDisclosure()

  const isTemplateCreateOn = useFeatureIsOn(
    GrowthBookFeatures.templateCreate as string,
  )

  const { createPdfPreview, isPdfPreviewLoading } = useCreatePdfPreview()

  const { mutateAsync, isLoading } = useCreateTemplateMutation({
    onSuccess: () => {
      toast({
        title: `New template ${templateName} created!`,
        status: 'success',
      })
      onCreateModalClose()
      navigate(`/admin/${routes.admin.templates.index}`)
    },
  })

  if (!isTemplateCreateOn)
    return <Navigate to={`${routes.admin.index}/${routes.admin.letters}`} />

  const isTemplateNameInvalid =
    templateName.trim() === '' ||
    !ACCEPTED_TEMPLATE_NAME_REGEX.test(templateName.trim())

  return (
    <VStack h="100%" display="flex" flexDirection="column">
      <HStack
        w={'100%'}
        py={4}
        px={10}
        display={'flex'}
        justify={'space-between'}
        position={'fixed'}
        zIndex={10}
        backgroundColor={'grey.50'}
        pointerEvents="auto"
      >
        <HStack spacing={0}>
          <Button
            onClick={() => {
              onExitModalOpen()
            }}
            variant={'unstyled'}
            border={'none'}
          >
            <BiX size={'1.7rem'} />
          </Button>
          <Tooltip
            label={
              templateName.trim() === ''
                ? 'Title cannot be empty'
                : !ACCEPTED_TEMPLATE_NAME_REGEX.test(templateName.trim())
                ? 'Title should contain only letters, numbers, spaces, and characters: —@\'"-()[]:#_&|,.?!/'
                : 'Write an appropriate title for your template. It will be shown to citizens.'
            }
            placement="right"
          >
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <BsPencilFill size="1rem" />
              </InputLeftElement>
              <Input
                value={templateName}
                onChange={(event) => setTemplateName(event.target.value)}
                fontWeight="500"
                id="template-name-editor"
                sx={{
                  // if not focused and invalid/ empty, style as error
                  // if not focused and valid, style as normal text
                  ':not(:focus)': {
                    border: isTemplateNameInvalid ? '1px solid' : 'none',
                    borderColor: isTemplateNameInvalid
                      ? 'red.500'
                      : 'transparent',
                    backgroundColor: isTemplateNameInvalid
                      ? 'white'
                      : 'transparent',
                    cursor: 'text',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                  // 44px is size of close icon, 40px is padding on top bar
                  width: 'calc(50vw - 44px - 40px)',
                }}
              />
            </InputGroup>
          </Tooltip>
        </HStack>
        <HStack spacing={4}>
          <Button
            alignSelf="start"
            onClick={onEditorIntroOpen}
            variant={'outline'}
            px={0}
            border="none"
            aria-label="Get help"
          >
            <BiHelpCircle size="1.7rem" />
          </Button>
          <Button
            variant={'outline'}
            onClick={() => createPdfPreview({ html: templateContent })}
            isLoading={isPdfPreviewLoading}
          >
            Preview PDF
          </Button>
          <Button
            isDisabled={!templateContent || isTemplateNameInvalid}
            alignSelf="start"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={async () => {
              if (templateName === template.name) {
                // if user has not renamed template, open modal for user to input name
                onCreateModalOpen()
              } else {
                await mutateAsync({
                  name: templateName.trim(),
                  html: templateContent,
                })
              }
            }}
            isLoading={isLoading}
          >
            Save Template
          </Button>
        </HStack>
      </HStack>
      <HStack
        w="full"
        justify="space-between"
        pt={13}
        display="flex"
        overflow="auto"
        spacing="0"
        h="full"
      >
        <VStack flex={5} height="100%" bg={'white'}>
          <TemplateEditor
            onContentChange={setTemplateContent}
            initialHtml={template?.html}
          />
        </VStack>
        <VStack flex={4} height={'100%'} bg={'slate.800'} overflow={'auto'}>
          <EditorLetterPreview templateContent={templateContent} />
        </VStack>
      </HStack>
      <EditorIntroModal
        isOpen={isEditorIntroOpen}
        onOpen={onEditorIntroOpen}
        onClose={onEditorIntroClose}
      />
      <CreateTemplateModal
        isOpen={isCreateModalOpen}
        onClose={onCreateModalClose}
        templateHtml={templateContent}
        defaultTemplateName={templateName}
        source={SOURCE_TYPE.CREATE}
      />
      <ExitEditorModal
        isOpen={isExitModalOpen}
        onClose={onExitModalClose}
        onConfirm={() => {
          navigate(`/${routes.admin.index}/${routes.admin.templates.index}/`)
        }}
      />
    </VStack>
  )
}
