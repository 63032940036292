import { IsDefined, IsNumber, IsOptional, IsString } from 'class-validator'

export enum TemplateUpdateType {
  HTML = 'HTML',
  NAME = 'NAME',
  SAMPLE_LETTER = 'SAMPLE_LETTER',
  ARCHIVE = 'ARCHIVE',
  RECOVER = 'RECOVER',
}

export class CreateTemplateDto {
  @IsDefined()
  @IsString()
  html: string
  @IsDefined()
  @IsString()
  name: string
}

export class GetTemplateDto {
  id: number
  fields: string[]
  html: string
  name: string
  createdAt: string
  updatedAt: string
  version: number
}

export class GetTemplateResponseDto {
  templates: GetTemplateDto[]
  count: number
}

export class UpdateTemplateDto {
  @IsDefined()
  @IsNumber()
  templateId: number
  @IsDefined()
  @IsString()
  html: string
  @IsDefined()
  @IsNumber()
  version: number
  @IsOptional()
  @IsString()
  name: string
}

export class TemplateVersionDto {
  @IsDefined()
  @IsNumber()
  versionNumber: number
  @IsDefined()
  @IsString()
  modifiedBy: string
  @IsDefined()
  updatedAt: Date
  @IsDefined()
  actionType: TemplateUpdateType
}

export class UploadImageResponseDto {
  url: string | null // null means that the image was not uploaded
}
