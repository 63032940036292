import {
  GrowthBookFeatures,
  GrowthBookFeatureValue,
} from '../types/feature-flag'

export const getMockGrowthbookClient = ({
  isDevEnv,
}: {
  isDevEnv: boolean
}) => ({
  loadFeatures: () => Promise.resolve(),
  isOn: (
    featureKey: string,
    fallbackValue = isDevEnv, // defaults to true in dev mode and false otherwise
  ): boolean => {
    console.log(
      `Feature ${featureKey} using fallbackValue ${fallbackValue.toString()} by default as Growthbook is not initialized`,
    )
    return fallbackValue
  },
  getFeatureValue: (
    featureKey: string,
    fallbackValue: GrowthBookFeatureValue,
  ) => {
    // TODO: workaround to enable physical sending e2e tests
    // returns template ids 1-1000 as mocked GrowthBookFeatures.ptvTemplateIdList
    // to mock that all templates have physical sending enabled
    if (featureKey === GrowthBookFeatures.ptvTemplateIdList) {
      console.log(
        `Feature ${featureKey} using fallbackValue of 1,2,3,4,5...1000 by default as Growthbook is not initialized`,
      )
      return Array.from({ length: 1000 }, (_, i) => (i + 1).toString()).join(
        ',',
      )
    }
    console.log(
      `Feature ${featureKey} using fallbackValue ${
        fallbackValue?.toString() || ''
      } by default as Growthbook is not initialized`,
    )
    return fallbackValue
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setAttributes: (_attributes: unknown) =>
    console.log(`Growthbook is not initialized, not setting attributes`),
})
