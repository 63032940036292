import { Image } from '@chakra-ui/react'
import { useFeatureValue } from '@growthbook/growthbook-react'

import DownloadCSVPreview from '~/assets/DownloadCSVPreview.svg'
import PTVFailSMSPreview from '~/assets/PTVFailSMSPreview.svg'
import PTVSuccessSMSPreview from '~/assets/PTVSuccessSMSPreview.svg'
import { useAdminAuth } from '~features/auth/context/AdminProtectedContext'
import { CitizenNotificationMethod } from '~shared/dtos/letters.dto'
import { GetTemplateDto } from '~shared/dtos/templates.dto'
import { GrowthBookFeatures } from '~shared/types/feature-flag'

import { EmptyPreview } from './EmptyPreview'
import { PreviewEmailNotification } from './PreviewEmailNotification'
import { PreviewSMSNotification } from './PreviewSmsNotification'
import { PreviewTemplate } from './PreviewTemplate'

interface PreviewNotificationMethodProps {
  citizenNotificationMethod: CitizenNotificationMethod
  template: GetTemplateDto
}

export const PreviewNotificationMethod = ({
  citizenNotificationMethod,
  template,
}: PreviewNotificationMethodProps) => {
  const agencyMapping = useFeatureValue(GrowthBookFeatures.agencyMapping, {})
  const { adminUser } = useAdminAuth()
  return citizenNotificationMethod === CitizenNotificationMethod.SMS ? (
    <PreviewSMSNotification templateId={template.id} {...agencyMapping} />
  ) : citizenNotificationMethod === CitizenNotificationMethod.EMAIL ? (
    <PreviewEmailNotification
      templateName={template.name}
      userEmail={adminUser?.email || 'support@letters.gov.sg'}
      {...agencyMapping}
    />
  ) : citizenNotificationMethod === CitizenNotificationMethod.PHYSICAL ? (
    <PreviewTemplate
      templateHtml={template.html}
      isPlaceholdersHighlighted={true}
    />
  ) : citizenNotificationMethod === CitizenNotificationMethod.NONE ? (
    <Image
      src={DownloadCSVPreview}
      h="96%"
      maxW={'60%'}
      right="0"
      bottom="0"
      position={'fixed'}
    />
  ) : (
    <EmptyPreview
      title="Message Preview"
      subtitle={
        <>
          This is a preview of what recipients see depending <br /> on the
          delivery method you selected.
        </>
      }
    />
  )
}
