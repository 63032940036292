import { Avatar, Box, Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'
import { BiBattery, BiChevronLeft, BiSignal5, BiWifi } from 'react-icons/bi'

import { useGovSgMetadata } from '~features/issue/hooks/agency-metadata.hooks'
import { GrowthBookFeatures } from '~shared/types/feature-flag'
import { getSmsMessageTemplate } from '~shared/util/message'

export interface PreviewSMSNotificationProps {
  templateId: number
  agencyName?: string
}

export const PreviewSMSNotification = ({
  templateId,
  agencyName,
}: PreviewSMSNotificationProps): JSX.Element => {
  const smsTemplateMap = useFeatureValue(
    GrowthBookFeatures.smsTemplateMap,
    {},
  ) as { [key: string]: string }

  // feature flag for domains that can use govsg configured directly on growthbook
  const canUseGovSgSending = useFeatureIsOn(
    GrowthBookFeatures.govsgSending as string,
  )

  // Get the value then split by ',' to get each individual ids
  const ptvTemplateIdList = useFeatureValue(
    GrowthBookFeatures.ptvTemplateIdList,
    '',
  ).split(',')
  const isPaPtvSms = ptvTemplateIdList.includes(templateId.toString())

  // TODO: temporary, to remove after gov.sg rollout on 30 Jun
  // after 30 Jun, all .gov.sg users will use govsg format by default
  const isGovSgSenderIdEnabled = useFeatureIsOn(
    GrowthBookFeatures.govsgSenderId as string,
  )

  const { govSgMetadata } = useGovSgMetadata({
    enabled: canUseGovSgSending && isGovSgSenderIdEnabled,
  })

  return (
    <VStack
      bg="white"
      maxW="md"
      mx={14}
      mt={14}
      borderTopRadius="2xl"
      pb={12}
      boxShadow="lg"
    >
      <Box bg="gray.100" p={4} w="100%" borderTopRadius={'2xl'}>
        <VStack w="100%">
          <HStack justify={'space-between'} w="100%">
            <Text textStyle="caption-1" textColor={'black'}>
              <b>9:41</b>
            </Text>
            <HStack>
              <BiSignal5 color="black" />
              <BiWifi color="black" />
              <BiBattery color="black" />
            </HStack>
          </HStack>
          <Flex justifyContent="space-between" alignItems="center" w="full">
            <BiChevronLeft size="38" color="black" />
            <VStack w="full" textAlign="center">
              <Avatar bg="grey.200" />
              {canUseGovSgSending && isGovSgSenderIdEnabled ? (
                <Text textStyle="caption-2">gov.sg</Text>
              ) : (
                <Text>LetterSG</Text>
              )}
            </VStack>
            <Box flexShrink={0} visibility="hidden">
              right
            </Box>
          </Flex>
        </VStack>
      </Box>
      <VStack spacing={4} pt={8} px={8} pb={20}>
        <Box
          p={4}
          position="relative"
          backgroundColor="gray.200"
          borderRadius="xl"
          _after={{
            // add chat bubble tail
            content: '""',
            position: 'absolute',
            bottom: '10px',
            left: '-10px',
            width: 0,
            height: 0,
            border: '20px solid transparent',
            borderBottomColor: 'gray.200',
            borderTop: 0,
            marginBottom: '-10px',
            transform: 'rotate(0deg)',
            zIndex: 0,
          }}
        >
          {canUseGovSgSending && isGovSgSenderIdEnabled && (
            <>
              <Text whiteSpace="pre-line">
                {govSgMetadata?.govSgCampaignHeader || 'Letters.gov.sg'}
              </Text>
              <Text whiteSpace="pre-line">---</Text>
            </>
          )}
          <Text whiteSpace="pre-line">
            {getSmsMessageTemplate({
              customSmsMessageMapping: smsTemplateMap,
              templateId,
              agencyName,
              letterPublicId: '{{letter_link}}',
              domainName: window.location.origin,
              govSgFormatEnabled: canUseGovSgSending && isGovSgSenderIdEnabled,
              govSgAgencyHasCustomHeader: Boolean(
                govSgMetadata?.govSgCampaignHeader,
              ),
              isPaPtvSms: isPaPtvSms,
            })}
          </Text>
          {canUseGovSgSending && isGovSgSenderIdEnabled && (
            <>
              <Text whiteSpace="pre-line">---</Text>
              <Text>
                This is an automated SMS from the Singapore Government. Please
                do not reply.
              </Text>
            </>
          )}
        </Box>
      </VStack>
    </VStack>
  )
}
