import { ADDRESS_KEYS } from '../constants/letters'
import {
  ACCEPTED_CITIZEN_EMAIL_REGEX,
  ACCEPTED_CITIZEN_POSTAL_CODE_REGEX,
  ACCEPTED_SINGAPORE_PHONE_NUMBERS_REGEX,
} from '../constants/regex'

export const isValidEmail = (email: string): boolean => {
  return Boolean(email.match(ACCEPTED_CITIZEN_EMAIL_REGEX))
}

export const isValidPhoneNumber = (phoneNumber: string): boolean => {
  return Boolean(phoneNumber.match(ACCEPTED_SINGAPORE_PHONE_NUMBERS_REGEX))
}

export const isValidPostalCode = (postalCode: string): boolean => {
  return Boolean(postalCode.match(ACCEPTED_CITIZEN_POSTAL_CODE_REGEX))
}

export const getRecipientNameToShowInBatchDetails = (
  recipient: string,
): string => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const recipientObj = JSON.parse(recipient)
    // Check if recipientObj has the necessary fields
    if (!ADDRESS_KEYS.every((key) => key in recipientObj)) {
      return recipient
    }
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
    return `${recipientObj.block_house_no} ${recipientObj.street_name}, ${recipientObj.building_name} ${recipientObj.floor_unit_no}, Singapore ${recipientObj.postal_code}`
  } catch (e) {
    return recipient
  }
}
